import React from "react"

import { ThemeContextProvider } from "../util"
// import Snowfall from 'react-snowfall'

import { Helmet } from "react-helmet"
import { Campaign, Navigation, Hero, Lunch, Catering, Footer, Quotation, Contact } from "../sections"

const NyarPage:React.FC = () => {
  return (
    <ThemeContextProvider>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <title>Freddes mat - Catering och Lunch i Härnösand</title>
        <meta name="description" content="Freddes mat - Catering och Lunch i Härnösand" />
        <meta name="keywords" content="Lunch, Catering, Lokal, Eventlokal, Veckans lunch, Härnösand" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        <script
          async
          type="text/javascript"
          src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=JxpwK7"
        ></script>

        <style type="text/css">{`
             html {
               font-size: 100%;
               margin: 0;
               padding: 0;
               background:#fdfbfa;
             }
             body {
               padding: 0;
               margin: 0;
               background:#fdfbfa;
             }
             * {
               font-family: 'Roboto Condensed', sans-serif;
             }
               `}</style>
      </Helmet>
      <Navigation />
      <Hero id="start" />
      {/* {window != undefined && (<Snowfall />)} */}
       <Footer /> 
    </ThemeContextProvider>
  )
}

export default NyarPage
